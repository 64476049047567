import React from 'react'
import Truncate from 'react-truncate'
import styled from 'styled-components'
import { device } from '../../utils/screenSizes'
import loadable from '@loadable/component'
const Ellipsis = loadable(() => import('react-ellipsis-pjs'))

export const StyledTitle = styled.div`
  word-break: normal;
  color: rgb(79, 79, 80);
  direction: rtl;
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-weight: bold;
  margin: 0;
  font-size: 22px;
  line-height: 28px;

  @media ${device.s} {
    font-size: 17px;
    line-height: 21px;
  }
`

const StyledDescription = styled.div`
  color: rgb(132, 132, 132);
  direction: rtl;
  text-align: right;
  font-family: 'Neue Haas Grotesk Bloomberg';
  margin: 0;
  font-size: 15px;
  line-height: 25px;
  overflow: hidden;
  box-sizing: border-box;

  @media ${device.s} {
    font-size: 12px;
    line-height: 18px;
  }
`

const Description = ({ lines, text }) => {
  return (
    <StyledDescription>
      <Truncate lines={lines} ellipsis={<span>...</span>}>
        {text}
      </Truncate>
    </StyledDescription>
  )
}

const Title = ({ text, style = {}, lines = 3, ...rest }) => {
  if (lines === 0) {
    return (
      <StyledTitle {...rest} style={style}>
        {text}
      </StyledTitle>
    )
  }

  return (
    <StyledTitle {...rest}>
      <Ellipsis lines={lines} style={style} text={text} />
    </StyledTitle>
  )
}

export { Title, Description }
