import React from 'react'
import Modal from 'react-modal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    border: 'none',
    padding: 0,
    width: 'calc(100% - 84px)',
    height: '90%'
  },
  overlay: {
    zIndex: 99999,
    background: 'rgba(0,0,0,0.6)'
  }
}

const ModalWrapper = ({ children, style = {}, ...rest }) => {
  return (
    <Modal style={customStyles} {...rest}>
      <div dir="rtl">{children}</div>
    </Modal>
  )
}

export default ModalWrapper
