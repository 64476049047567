import 'react-datepicker/dist/react-datepicker.css'

import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import ReactSlider from 'react-slider'
import { addDays, subYears, subDays, isAfter, isBefore } from 'date-fns'
import DateTimeService from '../../../utils/common'

import {
  FiltersContainer,
  FilterHeader,
  FilterSection,
  FilterSectionHeader,
  FilterOptions,
  FilterRow,
  Submit,
  DateInput,
  DateContainer
} from './styles'
import arrow from '../assets/arrow-icon.svg'
import filterIcon from '../assets/filter-icon.png'
import confirmIcon from '../assets/confirm-icon.png'
import removeIcon from '../assets/remove-icon.png'
import refreshIcon from '../assets/refresh-icon.svg'

const timeFilters = [
  { value: 'lastDay', label: 'آخر 24 ساعة' },
  { value: 'lastWeek', label: 'الأسبوع الماضي' },
  { value: 'lastMonth', label: 'الشهر الماضي' },
  { value: 'lastYear', label: 'العام الماضي  ' },
  { value: 'customRange', label: 'تاريخ محدد' }
]

const typeFilters = [
  { value: 'all', label: 'الكل' },
  { value: 'article', label: 'أخبار' },
  { value: 'headline', label: 'آخر الأخبار' },
  { value: 'video', label: 'فيديو ' },
  { value: 'image', label: 'صور' },
  { value: 'infographics', label: 'قصص' },
  { value: 'livetv', label: 'تلفزيون الشرق' }
]

const CustomInput = ({ value, onClick, label }) => (
  <DateInput onClick={onClick}>
    <label>{label}</label>
    <div>
      <time>
        {DateTimeService.formatInLocalTime(
          new Date(value).toISOString(),
          'dd MMM yyyy'
        )}
      </time>
    </div>
  </DateInput>
)

const Filters = ({
  filters,
  setFilters,
  categories,
  language,
  immediateUpdate = true,
  setFiltersVisibility,
  showTimeWeightFilter = false
}) => {
  const [collapsed, setCollapsed] = useState({})
  const [params, setParams] = useState({})

  const handleChange = (field, value) => {
    if (immediateUpdate) {
      setFilters({ ...filters, [field]: value })
    } else {
      setParams({ ...params, [field]: value })
    }
  }

  const searchCategory =
    !immediateUpdate && params.hasOwnProperty('searchCategory')
      ? params.searchCategory
      : filters.searchCategory
  const selectedRange =
    !immediateUpdate && params.hasOwnProperty('selectedRange')
      ? params.selectedRange
      : filters.selectedRange
  const type =
    !immediateUpdate && params.hasOwnProperty('type')
      ? params.type
      : filters.type
  const startDate =
    params.startDate || filters.startDate || subYears(new Date(), 1)
  const endDate = params.endDate || filters.endDate || new Date()

  return (
    <FiltersContainer>
      <FilterHeader>
        <div>
          <img src={filterIcon} loading="lazy" alt="Filter Icon" />
          تصفية النتائج
        </div>

        <div
          className="refresh"
          onClick={() => {
            setFilters({
              selectedRange: 'lastMonth',
              type: 'all',
              searchCategory: []
            })
            setParams({
              selectedRange: 'lastMonth',
              type: 'all',
              searchCategory: []
            })
          }}
        >
          <img src={refreshIcon} loading="lazy" alt="Refresh Icon" />
          إزالة المرشحات
        </div>
      </FilterHeader>

      {showTimeWeightFilter && (
        <FilterSection>
          <FilterSectionHeader>
            <div>time weight</div>
            <img
              loading="lazy"
              alt="Arrow Icon"
              src={arrow}
              onClick={() => {
                setCollapsed({
                  ...collapsed,
                  timeWeight: !collapsed.timeWeight
                })
              }}
              style={
                collapsed.timeWeight ? { transform: 'rotate(180deg)' } : {}
              }
            />
          </FilterSectionHeader>
          <FilterOptions>
            <FilterRow>
              <div style={{ direction: 'rtl', width: '200px', height: 30 }}>
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="slider-thumb"
                  trackClassName="slider-track"
                  step={0.1}
                  min={0}
                  max={1}
                  defaultValue={0}
                  onChange={(value) => {
                    handleChange('timeWeight', value)
                  }}
                  renderThumb={(props, state) => (
                    <div {...props}>
                      <div className={'slider-value'}>{state.valueNow}</div>
                    </div>
                  )}
                />
              </div>
            </FilterRow>
          </FilterOptions>
        </FilterSection>
      )}

      <FilterSection>
        <FilterSectionHeader>
          <div>التاريخ</div>
          <img
            loading="lazy"
            alt="Arrow Icon"
            src={arrow}
            onClick={() => {
              setCollapsed({ ...collapsed, time: !collapsed.time })
            }}
            style={collapsed.time ? { transform: 'rotate(180deg)' } : {}}
          />
        </FilterSectionHeader>

        <FilterOptions collapsed={collapsed.time}>
          {timeFilters.map((filter) => (
            <FilterRow
              key={filter.value}
              isSelected={selectedRange === filter.value}
              onClick={() => handleChange('selectedRange', filter.value)}
            >
              {selectedRange === filter.value && (
                <div className="confirmIcon">
                  <img src={confirmIcon} loading="lazy" alt="Confirm Icon" />
                </div>
              )}

              <div className="value">{filter.label}</div>

              {selectedRange === filter.value && (
                <div
                  className="removeIcon"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    // default it to lastMonth when removing any value from this filter
                    handleChange('selectedRange', 'lastMonth')
                  }}
                >
                  <img src={removeIcon} loading="lazy" alt="Remove Icon" />
                </div>
              )}
            </FilterRow>
          ))}
        </FilterOptions>

        {selectedRange === 'customRange' && (
          <DateContainer>
            <div>
              <DatePicker
                popperModifiers={{ preventOverflow: { enabled: true } }}
                locale="ar-LY"
                selected={startDate}
                onChange={(date) => {
                  if (isAfter(date, endDate)) {
                    date = subDays(endDate, 1)
                  }
                  handleChange('startDate', date)
                }}
                customInput={<CustomInput label="من" />}
              />
            </div>

            <div>
              <DatePicker
                popperModifiers={{ preventOverflow: { enabled: true } }}
                locale="ar-LY"
                selected={endDate}
                onChange={(date) => {
                  if (isBefore(date, startDate)) {
                    date = addDays(startDate, 1)
                  }
                  handleChange('endDate', date)
                }}
                customInput={<CustomInput label="إلى" />}
              />
            </div>
          </DateContainer>
        )}
      </FilterSection>

      <FilterSection>
        <FilterSectionHeader>
          <div>الأنواع</div>
          <img
            loading="lazy"
            alt="Collapse/Expand"
            src={arrow}
            onClick={() => {
              setCollapsed({ ...collapsed, type: !collapsed.type })
            }}
            style={collapsed.type ? { transform: 'rotate(180deg)' } : {}}
          />
        </FilterSectionHeader>

        <FilterOptions collapsed={collapsed.type}>
          {typeFilters.map((filter) => (
            <FilterRow
              key={filter.value}
              isSelected={type === filter.value}
              onClick={() => handleChange('type', filter.value)}
            >
              {type === filter.value && (
                <div className="confirmIcon">
                  <img src={confirmIcon} loading="lazy" alt="Confirm Icon" />
                </div>
              )}

              <div className="value">{filter.label}</div>

              {type === filter.value && (
                <div
                  className="removeIcon"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    // default it to article when removing any value from this filter
                    handleChange('type', 'all')
                  }}
                >
                  <img src={removeIcon} loading="lazy" alt="Remove Icon" />
                </div>
              )}
            </FilterRow>
          ))}
        </FilterOptions>
      </FilterSection>

      <FilterSection>
        <FilterSectionHeader>
          <div>التصنيفات</div>
          <img
            loading="lazy"
            alt="Collapse/Expand"
            src={arrow}
            onClick={() =>
              setCollapsed({
                ...collapsed,
                category: !collapsed.category
              })
            }
            style={collapsed.category ? { transform: 'rotate(180deg)' } : {}}
          />
        </FilterSectionHeader>

        <FilterOptions collapsed={collapsed.category}>
          {categories
            .filter((cat) => cat.showInMenu)
            .map((cat) => (
              <FilterRow
                onClick={() => {
                  if (searchCategory.indexOf(cat[language]) === -1) {
                    handleChange('searchCategory', [
                      ...searchCategory,
                      cat[language]
                    ])
                  }
                }}
                key={cat.id}
              >
                {searchCategory.indexOf(cat[language]) > -1 && (
                  <div className="confirmIcon">
                    <img src={confirmIcon} loading="lazy" alt="Confirm Icon" />
                  </div>
                )}

                <div className="value"> {cat[language]}</div>

                {searchCategory.indexOf(cat[language]) > -1 && (
                  <div
                    className="removeIcon"
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      handleChange(
                        'searchCategory',
                        searchCategory.filter((item) => item !== cat[language])
                      )
                    }}
                  >
                    <img src={removeIcon} loading="lazy" alt="Remove Icon" />
                  </div>
                )}
              </FilterRow>
            ))}
        </FilterOptions>
      </FilterSection>

      {!immediateUpdate && (
        <Submit
          onClick={() => {
            setFilters({ ...filters, ...params })
            setFiltersVisibility(false)
          }}
        >
          بحث
        </Submit>
      )}
    </FiltersContainer>
  )
}

export default Filters
