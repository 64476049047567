import React, { useRef } from 'react'
import Highlighter from 'react-highlight-words'
import { breakpoints } from '../../constants/app'
import useViewportSize from '../../utils/useViewportSize'
import { navigate } from 'gatsby'
import BackgroundCover from '../background/background'

import { Title } from './Ellipsis'

import {
  ResultCardContainer,
  TextContainer,
  ArticleInfo,
  ResultBody,
  CCImage,
  ResultCard,
  PlayVideoButton,
  ReplayImageContainer,
  ResultCardBackground,
  SearchResultCardContainer
} from './styles'

import ccIcon from './assets/cc.svg'
import playButton from './assets/playButton.svg'
import renderDate from './renderDate'

import { getCcText } from '../../utils/liveTvSearchHelper'

import './index.scss'

const LiveTvCard = (props) => {
  const { result, searchTerm, type = null } = props
  const { width: screenWidth } = useViewportSize()
  const textContainerRef = useRef(null)

  const handleReplay = (result) => {
    navigate(`/live/`, {
      state: {
        result,
        searchTerm
      }
    })
  }

  const imageHeight = screenWidth > breakpoints.m ? 200 : 187
  const imageWidth = screenWidth > breakpoints.m ? 250 : 143

  const style = { borderRadius: '0px 0px 0px 10px' }

  const WrapperComponent = type
    ? SearchResultCardContainer
    : ResultCardContainer

  return (
    <WrapperComponent onClick={() => handleReplay(result)}>
      <ResultCard className="cardArticleContent">
        <ArticleInfo>
          <div className="date">{renderDate(result.start_dt)}</div>
        </ArticleInfo>
        <TextContainer ref={textContainerRef}>
          <Title
            text={result.title}
            style={{ cursor: 'pointer', maxHeight: 28 }}
            lines={1}
          />
          <ResultBody>
            <CCImage src={ccIcon} alt="CC" loading="lazy" />
            <Highlighter
              highlightClassName="hightlightColor"
              className="cctext"
              searchWords={[searchTerm]}
              highlightStyle={{ fontWeight: 'bold' }}
              textToHighlight={getCcText(result, searchTerm)}
            />
          </ResultBody>
          <PlayVideoButton onClick={() => handleReplay(result)}>
            <span>شغل الفيديو</span>
            <ReplayImageContainer>
              <img src={playButton} alt="replay" loading="lazy" />
            </ReplayImageContainer>
          </PlayVideoButton>
        </TextContainer>
      </ResultCard>
      <ResultCardBackground className="cardArticleBackground">
        <BackgroundCover
          title={result.title}
          src={result.image}
          imageQuality={90}
          imageDpr={2}
          fullScreen={false}
          lazyload={true}
          style={style}
          isSecureImg={false}
          imageObjectFit={true}
          imageUrl={`${result.image}?h=${imageHeight}&q=80&fit=crop&w=${imageWidth}&fp-x=1&fp-y=1&fp-z=1&crop=focalpoint`}
        />
      </ResultCardBackground>
    </WrapperComponent>
  )
}

export default LiveTvCard
