import styled from 'styled-components'
import { device } from '../../../utils/screenSizes'

export const FiltersContainer = styled.div`
  width: 286px;
  background-color: #fff;
  margin-left: 55px;

  @media ${device.s} {
    width: 100%;
    margin-left: 0;
  }
`

export const FilterHeader = styled.div`
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-size: 18px;
  font-weight: bold;
  background: rgb(235, 238, 238);
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 18px;
  justify-content: space-between;

  img {
    width: 13px;
    height: 13px;
    margin-left: 10px;
  }

  .refresh {
    color: rgb(198, 35, 38);
    font-family: 'Neue Haas Grotesk Bloomberg';
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }

  .refresh img {
    margin-left: 5px;
    margin-bottom: -2px;
  }

  @media ${device.s} {
    position: sticky;
    top: 0;
  }
`

export const FilterSection = styled.div`
  padding: 12px 18px;
  border-bottom: 1px solid #ededed;

  img {
    width: 14px;
    height: 7px;
    cursor: pointer;
  }
`

export const FilterSectionHeader = styled.div`
  color: rgb(51, 51, 51);
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FilterOptions = styled.div`
  max-height: ${({ collapsed }) => (collapsed ? 0 : 'fit-content')};
  margin-top: ${({ collapsed }) => (collapsed ? 0 : 10)}px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  display: grid;

  a,
  div {
    color: #000;
    font-family: 'Neue Haas Grotesk Bloomberg';
    font-size: 14px;
    line-height: 26px;
    text-decoration: none;
    cursor: pointer;
  }
`

export const FilterRow = styled.div`
  display: flex;
  align-items: center;

  ${({ isSelected }) => isSelected && `font-weight: bold;`}
  ${({ isSelected }) => isSelected && `margin-right: 8px;`}

  .confirmIcon {
    border: 1px solid #c62326;
    border-radius: 20px;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .removeIcon {
    margin-top: 5px;
  }

  .value {
    margin: 0 10px;
  }

  img {
    width: 10px;
    height: 10px;
    margin-right: 1px;
  }
`

export const Submit = styled.div`
  background: rgb(198, 35, 38);
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.2);
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: sticky;
  bottom: 0;
  cursor: pointer;
  font-weight: bold;
`

export const DateInput = styled.div`
  label {
    color: rgb(108, 108, 108);
    font-family: 'Neue Haas Grotesk Bloomberg';
    font-size: 12px;
  }

  div {
    border: 1px solid rgb(225, 225, 225);
    height: 29px;
    width: 105px;
    margin-top: 5px;
    color: rgb(51, 51, 51);
    font-family: 'Neue Haas Grotesk Bloomberg';
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`

export const DateContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;

  @media ${device.s} {
    width: 100%;
    margin-left: 0;
    grid-auto-flow: row;
  }
`
