import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import queryString from 'query-string'
import { isEmpty } from 'lodash'

import SearchGrid from '../../components/search/grid/searchGrid'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import SearchScreen from '../../components/search'
import { DESCRIPTION } from '../../constants/metaInfo'
import { addGoogleAnalyticsPageData } from '../../utils/googleAnalyticsEvents'

const SearchResults = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <Location>
      {({ location }) => {
        const qsParams = queryString.parse(location.search)
        const category = qsParams.category

        let seoTitle = 'Search results'

        if (category) {
          switch (category) {
            case 'سياسة':
              // politics
              seoTitle = 'سياسة | الشرق'
              break
            case 'اقتصاد':
              // economy
              seoTitle = 'اقتصاد | الشرق'
              break
            case 'رياضة':
              // sports
              seoTitle = 'رياضة | الشرق'
              break
            case 'علوم':
              // science
              seoTitle = 'علوم | الشرق'
              break
            case 'تكنولوجيا':
              // technology
              seoTitle = 'تكنولوجيا | الشرق'
              break
            case 'ثقافة':
              // culture
              seoTitle = 'ثقافة | الشرق'
              break
            case 'فن':
              // art
              seoTitle = 'فن | الشرق'
              break
            case 'صحة':
              // health
              seoTitle = 'صحة | الشرق'
              break
            case 'منوعات':
              // variety
              seoTitle = 'منوعات | الشرق'
              break
            case 'فيديو':
              // video
              seoTitle = 'فيديو | الشرق'
              break
            default:
              seoTitle = 'Search results'
          }
        }

        return (
          <Layout location={location} title={siteTitle} lang={'ar'}>
            <SEO title={seoTitle} lang="ar" description={DESCRIPTION} />

            {!isEmpty(qsParams) &&
              (qsParams.category ? (
                <SearchGrid
                  searchCategory={
                    location.search
                      ? queryString.parse(location.search).category
                      : null
                  }
                />
              ) : (
                <SearchScreen
                  searchTerm={qsParams.query}
                  searchAuthor={qsParams.author}
                  language={'ar'}
                />
              ))}

            {isEmpty(qsParams) && (
              <SearchScreen searchTerm={''} searchAuthor={''} language={'ar'} />
            )}
          </Layout>
        )
      }}
    </Location>
  )
}

export default SearchResults

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
