import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
`

export const Input = styled.input`
  color: rgb(79, 79, 80);
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-size: 16px;
  font-weight: bold;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 50px 0 15px;
  width: 100%;
  box-sizing: border-box;
`

export const InputContainer = styled.div`
  background: #fff;
  border-radius: 0px 5px;
  height: 48px;
  position: relative;
  margin-bottom: 20px;
  padding-left: 30px;
  flex-grow: 1;
  flex-shrink: 0;

  .search {
    position: absolute;
    right: 16px;
    top: 17px;
  }

  .clear {
    position: absolute;
    left: 15px;
    top: 15px;
    cursor: pointer;
  }

  @media ${device.s} {
    margin-top: 25px;
  }

  @media screen and (max-width: 812px) and (orientation: landscape),
    (max-width: 896px) and (orientation: landscape),
    (max-width: 926px) and (orientation: landscape) {
    margin-top: 35px;
  }

  @media ${device.tabletPortrait} {
    margin-top: 25px;
  }
`

export const BackButton = styled.button`
  display: block;
  width: 40px;
  height: 20px;
  border: 0;
  background: transparent;
  padding: 0 8px;
  margin: 0 6px 0 0;

  img {
    width: 28px;
    vertical-align: middle;
  }
`

export const SearchInputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`
export const FlexWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`

export const ArticleContainer = styled.div`
  background: #fff;
  border-radius: 0px 12px;
  height: 213px;
  min-height: 213px;
  margin-bottom: 15px;
  display: flex;
  flex: 1;

  @media ${device.s} {
    height: 200px;
  }
`

export const ArticleBackground = styled.div`
  height: 100%;
  width: 211px;
  cursor: pointer;

  @media ${device.s} {
    width: 143px;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  a {
    text-decoration: none;
  }
`

export const Article = styled.div`
  padding: 10px 10px 0 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const ArticleInfo = styled.div`
  display: flex;
  margin-bottom: 8px;

  a {
    font-size: 14px;
    color: #c62326 !important;
    text-decoration: none;
    font-family: 'Neue Haas Grotesk Bloomberg';
    display: flex;
    align-items: center;
  }

  .date {
    font-size: 14px;
    font-family: 'Neue Haas Grotesk Bloomberg';
    color: #333333;
    margin-top: 5px;
    margin-left: 10px;
  }

  @media ${device.s} {
    a,
    .date {
      font-size: 12px;
    }
  }
`

export const MobileAuthor = styled.div`
  color: rgb(108, 108, 108) !important;

  a {
    padding: 5px 0 5px 2px;
    font-size: 12px;
    color: rgb(108, 108, 108) !important;
    text-decoration: none;
    font-family: 'Neue Haas Grotesk Bloomberg';
    display: flex;
    align-items: center;
  }
`

export const Container = styled.div`
  display: flex;
`

export const LatestNewsContainer = styled.div`
  background-color: #fff;
  width: 211px;
  margin-right: 55px;
  max-height: 466px;
  overflow-y: hidden;
`

export const FilterMobile = styled.div`
  color: rgb(198, 35, 38);
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;

  img {
    height: 18px;
    width: 18px;
    margin-left: 5px;
  }
`

export const ModalCloseIcon = styled.div`
  position: fixed;
  right: 10px;
  top: 5px;
  z-index: 100000;
`

export const CategoryContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  padding: 0 10px 0 0;
  border-right: 1px solid #acacac;
  margin-left: 10px;

  a {
    padding-left: 5px;
  }
`

export const AuthorContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  border-right: 1px solid #acacac;
  padding-right: 10px;

  a {
    padding-left: 10px;
  }
`
export const ResultBody = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`

export const CCImage = styled.img`
  width: 23px;
  height: 14px;
  margin-left: 5px;
  background: #d8d8d8;
  color: #c62326;
  margin-top: 5px;
`
export const CCText = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: #6c6c6c;
  overflow: hidden;
  vertical-align: middle;
  max-height: 50px;

  @media ${device.s} {
    font-size: 13px;
    max-height: 42px;
  }
`

export const ResultCard = styled.div`
  padding: 17px 26px 0 10px;
  width: calc(100% - 286px);
  cursor: pointer;
  text-decoration: none;

  @media ${device.s} {
    padding: 12px 16px 0 10px;
    width: calc(100% - 163px);
  }
`
export const PlayVideoButton = styled.div`
  border: 1px solid #c61f1c;
  margin-top: 18px;
  width: 143px;
  height: 36px;
  display: flex;
  border-radius: 50px;
  align-items: center;
  cursor: pointer;
  background: #ffffff;

  @media ${device.s} {
    width: 97px;
    height: 25px;
  }

  span {
    font-size: 15px;
    color: #c62326;
    font-weight: 400;
    padding: 4px 16px 9px 0;

    @media ${device.s} {
      font-size: 10px;
      padding: 4px 10px 7px 0;
    }
  }
  img {
    justify-content: flex-end;

    @media ${device.s} {
      width: 8px;
      height: 8px;
    }
  }
`
export const ReplayImageContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #c61f1c;
  left: -14px;
  top: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media ${device.s} {
    width: 25px;
    height: 25px;
    left: -11px;
  }
`
export const ResultCardContainer = styled.div`
  background: #fff;
  border-radius: 0px 12px;
  height: 200px;
  margin-bottom: 15px;
  display: flex;
  flex: 1;

  @media ${device.s} {
    height: 187px;
  }

  a {
    text-decoration: none;
    width: 100%;
  }
`
export const ResultCardBackground = styled.div`
  width: 250px;
  cursor: pointer;
  position: absolute;
  overflow: hidden;
  left: 0;
  height: 200px;

  @media ${device.s} {
    width: 143px;
    height: 187px;
  }
`
export const ResultImage = styled.img`
  height: 193px;
  width: 100%;
  @media ${device.s} {
    height: 147px;
    width: 100%;
  }
`
export const ResultTitle = styled.span`
  font-family: 'Neue Haas Grotesk Bloomberg';
  color: rgb(79, 79, 80);
  font-weight: bold;
  max-height: 28px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin-top: 10px;
  font-size: 22px;

  @media ${device.s} {
    font-size: 17px;
    line-height: 21px;
  }
`
export const ResultContainer = styled.div`
  display: flex;
  flex-flow: column wrap;

  &.livetv {
    flex-flow: row wrap;
  }

  @media ${device.s}, @media ${device.isSmallDeviceLandscape} {
    flex-flow: row nowrap;
    overflow-x: visible;
    overflow-y: hidden;
    margin-bottom: 15px;

    &.livetv {
      flex-flow: row nowrap;
    }

    &:before {
      display: block;
      width: 113px;
      height: 295px;
      content: '';
      position: absolute;
      left: -1px;
      top: auto;
      z-index: 1;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.6998949237898284) 0%,
        rgba(255, 255, 255, 0) 98%
      );
    }
  }
`

export const ResultSubTitle = styled.h3`
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-weight: bold;
  font-size: 15px;
  color: #333333;
`

export const SearchArticleContainer = styled(ArticleContainer)`
  && {
    min-width: 100%;

    .cardArticleContent {
      padding: 10px 15px 20px 15px;
    }

    @media ${device.s}, @media ${device.isSmallDeviceLandscape} {
      min-width: 228px;
      display: flex;
      flex-flow: column-reverse nowrap;
      justify-content: flex-end;
      height: 295px;
      margin-left: 15px;
      margin-bottom: 0;

      .infinite-scroll-component & {
        margin-left: 0;
        margin-bottom: 15px;
      }

      .cardArticleBackground {
        width: 100%;
        max-height: 130px;
      }

      .cardArticleContent {
        padding: 10px 15px 20px 15px;
      }

      &:last-child {
        margin-left: 110px;
      }
    }
  }
`

export const SearchResultCardContainer = styled(ResultCardContainer)`
  && {
    min-width: 100%;
    height: 196px;
    flex-flow: row nowrap;
    justify-content: space-between;

    .cardArticleBackground {
      position: static;
      height: 100%;
      width: 211px;
      cursor: pointer;
      overflow: hidden;
    }

    .cardArticleContent {
      padding: 10px 15px 20px 15px;
      width: calc(100% - 211px);
    }

    @media ${device.s}, @media ${device.isSmallDeviceLandscape} {
      min-width: 228px;
      display: flex;
      flex-flow: column-reverse nowrap;
      justify-content: flex-end;
      margin-left: 15px;
      margin-bottom: 0;
      height: 275px;

      .cardArticleBackground {
        width: 228px;
        height: 130px;
        max-height: 130px;
        position: relative;
      }

      .cardArticleContent {
        padding: 10px;
        position: relative;
        width: 100%;
        box-sizing: border-box;
      }

      &:last-child {
        margin-left: 110px;
      }
    }
  }
`

export const LoadMoreBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoadMoreBtn = styled.div`
  border: 1px solid #c62326;
  border-radius: 0px 8px;
  background-color: #ffffff;
  width: ${({ width }) => (width ? width : '190px')};
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  span {
    color: #C62326;
    font: normal normal bold 14px/23px Neue Haas Grotesk Bloomberg;
  }

  :active {
    background-color: #C62326;
    span {
      color: #ffffff;
      font: normal normal bold 14px/23px Neue Haas Grotesk Bloomberg;
    }
  }
}
`
export const LatestNewsImageContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0 14px;
  overflow: hidden;
  position: relative;
  background: rgba(229, 229, 229, 255);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
