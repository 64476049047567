import React, { useState, useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'

import BackgroundCover from '../background/background'
import { breakpoints } from '../../constants/app'
import useViewportSize from '../../utils/useViewportSize'
import { sendEventItemClick } from '../../utils/sendEvent'
import testMobile from '../../utils/testMobile'

import { Title, Description } from './Ellipsis'
import renderDate from './renderDate'
import {
  ArticleContainer,
  TextContainer,
  Article,
  ArticleInfo,
  ArticleBackground,
  MobileAuthor,
  CategoryContainer,
  AuthorContainer,
  SearchArticleContainer,
  LatestNewsImageContainer
} from './styles'
import latestNewsImage from './assets/latest-news-image.png'

const Card = ({ ar, index, categories = [], type = null }) => {
  const [bodyLines, setBodyLines] = useState(0)
  const [bodyRef, setBodyRef] = useState()
  const textContainerRef = useRef(null)
  const { width: screenWidth } = useViewportSize()
  const BODY_LINE_HEIGHT = screenWidth && screenWidth <= breakpoints.m ? 25 : 26

  const isMobile = testMobile()

  useEffect(() => {
    if (bodyRef) {
      const bodyHeight = bodyRef.clientHeight
      const lines = Math.floor(bodyHeight / BODY_LINE_HEIGHT)

      setBodyLines(lines)
    }
  }, [bodyRef])

  const calculateTitleRows = () => {
    const node = textContainerRef.current

    if (node) {
      const paddingTop = parseInt(getComputedStyle(node).paddingTop)
      const paddingBottom = parseInt(getComputedStyle(node).paddingBottom)
      const height = node.offsetHeight - paddingTop - paddingBottom
      const rows = Math.floor(height / 28)

      return rows
    }

    return 0
  }

  const articleNavigationHandler = () => {
    sendEventItemClick({
      itemId: ar.id,
      pos: {
        column: 1,
        row: index + 1
      },
      itemType: ar.type === 'headline' ? 'headline' : 'article'
    })
  }

  const getCategoryUrl = (catName, lang) => {
    const defaultSlug = `${lang}/search/?category=${catName}`
    let category

    if (!isEmpty(categories)) {
      category = categories.find((categoryData) => {
        const categoryLabel =
          (lang === 'en' ? categoryData.en : categoryData.ar) || ''
        if (categoryLabel.trim() === catName.trim()) {
          return categoryData.slug
        }
      })
    }

    return category ? category.slug : defaultSlug
  }

  const handleSearchResultClick = () => {
    dataLayer.push({
      event: 'asEvent',
      analyticsEventLabel: 'Search',
      eventCategory: 'Search',
      eventAction: 'Search result click',
      eventLabel: index + 1
    })
  }

  const getLink = () => {
    if (ar.type === 'headline') {
      return `/latest-news/${ar.id}`
    }

    if (ar.isShortHandArticle) {
      return `/specials/${ar.slug}`
    }

    return `/ar/${ar.slug}`
  }

  const WrapperComponent = type ? SearchArticleContainer : ArticleContainer

  return (
    <WrapperComponent>
      <Article className="cardArticleContent">
        <ArticleInfo>
          <div className="date">
            {renderDate(ar.publishedAt || ar.createdAt || ar.updatedAt)}
          </div>
          {ar.categories && ar.categories.length > 0 && (
            <CategoryContainer className="category-container">
              {ar.categories.map((category, index) => [
                index > 0 && ' ،',
                <a
                  href={encodeURI(getCategoryUrl(category, ar.lang || 'ar'))}
                  key={`category_${index}`}
                  target="_blank"
                >
                  {category}
                </a>
              ])}
            </CategoryContainer>
          )}
          {screenWidth > breakpoints.m && ar.authors && ar.authors.length > 0 && (
            <AuthorContainer>
              {ar.authors.map((author, index) => [
                index > 0 && ', ',
                <a
                  href={`/${ar.lang}/search/?author=${author.name}`}
                  key={`author_${index}`}
                  target="_blank"
                >
                  {author.name}
                </a>
              ])}
            </AuthorContainer>
          )}
        </ArticleInfo>

        <TextContainer ref={textContainerRef}>
          <a
            href={getLink()}
            onClick={() => handleSearchResultClick()}
            target="_blank"
          >
            <Title
              text={ar.title}
              onClick={() => articleNavigationHandler()}
              style={{ cursor: 'pointer' }}
              lines={calculateTitleRows()}
            />
          </a>
          {screenWidth <= breakpoints.m && ar.authors && ar.authors.length > 0 && (
            <MobileAuthor>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'wrap'
                }}
              >
                {ar.authors.map((author, index) => [
                  index > 0 && ', ',
                  <a
                    href={`/${ar.lang}/search/?author=${author.name}`}
                    key={`author_${index}`}
                    target="_blank"
                  >
                    {author.name}
                  </a>
                ])}
              </div>
            </MobileAuthor>
          )}
          <a
            href={getLink()}
            onClick={() => handleSearchResultClick()}
            style={{ flex: 1 }}
            ref={(ref) => setBodyRef(ref)}
            target="_blank"
          >
            <div style={{ flex: 1 }}>
              {bodyLines > 0 && (
                <Description
                  text={ar.type === 'headline' ? ar.body : ar.contentAr}
                  lines={isMobile && type && bodyLines > 3 ? 3 : bodyLines}
                />
              )}
            </div>
          </a>
        </TextContainer>
      </Article>

      <ArticleBackground
        className="cardArticleBackground"
        onClick={() => articleNavigationHandler()}
      >
        <a
          href={getLink()}
          onClick={() => handleSearchResultClick()}
          target="_blank"
        >
          {ar.type === 'headline' ? (
            <LatestNewsImageContainer>
              <img src={latestNewsImage} alt={ar.title} />
            </LatestNewsImageContainer>
          ) : (
            <BackgroundCover
              title={ar.title}
              articleId={ar.id}
              src={ar.mainImageUrl}
              disableVideoClick
              imageQuality={90}
              imageDpr={2}
              videoSrc={ar.mainVideoUrl}
              forceVideo={false}
              fullScreen={false}
              imageMeta={ar.mainImage}
              disableControls={true}
              autoplay={false}
              lazyload={true}
              style={{
                borderRadius: '0px 0px 0px 10px'
              }}
              showPlayIcon
            />
          )}
        </a>
      </ArticleBackground>
    </WrapperComponent>
  )
}

export default Card
