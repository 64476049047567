import React, { useState, useEffect, useRef } from 'react'
import { navigate } from 'gatsby'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { debounce } from 'lodash'

import useViewportSize from '../../utils/useViewportSize'
import LatestNews from '../LatestNews'
import { breakpoints, checkLandscape } from '../../constants/app'
import { GridLoader, Spinner } from '../grid/grid.styled'
import Modal from './Modal'
import { sendEventSearch } from '../../utils/sendEvent'
import SocialControls from '../socialcontrols/socialcontrols'

import Card from './Card'
import LiveTvCard from './LiveTvCard'
import Results from './Results'
import Filters from './Filters'
import MobileLiveTv from '../livetv/mobileLiveTv'
import {
  SearchContainer,
  InputContainer,
  Input,
  Container,
  LatestNewsContainer,
  FilterMobile,
  ModalCloseIcon,
  ResultContainer,
  ResultSubTitle,
  SearchInputWrapper,
  FlexWrapper,
  BackButton,
  LoadMoreBtnContainer,
  LoadMoreBtn
} from './styles'

import { FiltersContainer } from './Filters/styles.js'

import searchIcon from './assets/search.svg'
import filterIcon from './assets/filter-icon.png'
import clearIcon from './assets/clear-icon.svg'
import closeIcon from './assets/close-btn.svg'
import { device } from '../../utils/screenSizes'
import testMobile from '../../utils/testMobile'

import { subYears, subMonths, subWeeks, subDays } from 'date-fns'
import DateTimeService from '../../utils/common'
import BackIcon from '/static/back.svg'

const Search = ({
  searchTerm = '',
  searchAuthor,
  fetchSearch,
  language,
  search,
  resetSearch,
  categories,
  fetchLiveTvSearch,
  liveTvSearch,
  quickLinks,
  hideLiveTvMobile,
  ...props
}) => {
  const [searchText, setSearchText] = useState(searchTerm)
  const [isFocused, setIsFocused] = useState(false)
  const [visibleFilters, setFiltersVisibility] = useState(false)
  const { width } = useViewportSize()
  const searchInputRef = useRef()
  const [showTimeWeightFilter, setShowTimeWeightFilter] = useState(false)

  const isLandScape = checkLandscape()
  const isTablet = device.isTabletPortrait()

  let queryString = typeof window !== 'undefined' ? window.location.search : ''
  let urlParams = new URLSearchParams(queryString)
  let articleType = urlParams.has('type') ? urlParams.get('type') : 'all'
  let selectedRange = urlParams.has('range')
    ? urlParams.get('range')
    : 'lastMonth'

  const [filters, setFilters] = useState({
    selectedRange: selectedRange,
    type: articleType,
    searchCategory: []
  })

  useEffect(() => {
    setFilters({
      ...filters,
      type: articleType,
      selectedRange
    })
  }, [articleType, selectedRange])

  const getIsLiveTvSearch = () => filters && filters.type === 'livetv'

  const [cardResults, setCardResults] = useState({
    data: {
      articles: []
    }
  })

  useEffect(() => {
    if (getIsLiveTvSearch()) {
      let liveTvSearchData = liveTvSearch.data
        ? { ...liveTvSearch, data: { articles: liveTvSearch.data } }
        : { ...liveTvSearch, data: { articles: [] } }
      setCardResults(liveTvSearchData)
    } else {
      setCardResults(search)
    }
  }, [search, liveTvSearch])

  useEffect(() => {
    if (localStorage.getItem('showTimeWeightFilter')) {
      const showTimeWeightFilter = localStorage.getItem('showTimeWeightFilter')
      if (showTimeWeightFilter === 'true') {
        setShowTimeWeightFilter(true)
      }
    }
  }, [])

  useEffect(() => {
    fetchData({ page: 0 }, searchTerm)
  }, [searchTerm, searchAuthor, Object.values(filters).join(',')])

  useEffect(() => {
    setSearchText(searchTerm)
  }, [searchTerm])

  const handleLoadMore = (key) => {
    resetSearch()
    window.scrollTo(0, 0)

    navigate(
      `/${language}/search/?query=${searchTerm}&type=${key || 'all'}&range=${
        selectedRange || 'lastMonth'
      }`
    )

    filters.type = key
    fetchData({ page: 0 }, searchTerm)
  }

  const fetchData = (params = {}, searchTerm) => {
    if (!searchTerm || searchTerm === '') {
      return
    }

    const { selectedRange, type, searchCategory, timeWeight } = filters
    if (type === 'livetv' && searchTerm) {
      resetSearch()
      const payload = {
        q: searchTerm,
        limit: 100
      }

      fetchLiveTvSearch(payload)

      return
    }

    if (params.page === 0) {
      resetSearch()
    }

    if (selectedRange) {
      params = { ...params, ...getRangeDates(selectedRange) }
    }

    if (
      showTimeWeightFilter &&
      typeof timeWeight !== 'undefined' &&
      timeWeight >= 0
    ) {
      params = { ...params, timeWeight }
    }

    if (type && type !== 'article') {
      if (type === 'infographics') {
        params.article_type = 'infographic'
      } else {
        params.item_type = type
        params.getAssociatedArticle = true
      }
    }

    fetchSearch({
      lang: language,
      page: search.page,
      searchTerm: searchTerm || searchText,
      searchCategory,
      searchAuthor,
      ...params
    })
  }

  const getRangeDates = (dateFilter) => {
    let dateStart = DateTimeService.formattedDateTime(subYears(new Date(), 1))
    let dateEnd = DateTimeService.formattedDateTime()

    if (dateFilter === 'lastDay') {
      dateStart = DateTimeService.formattedDateTime(subDays(new Date(), 1))
    } else if (dateFilter === 'lastWeek') {
      dateStart = DateTimeService.formattedDateTime(subWeeks(new Date(), 1))
    } else if (dateFilter === 'lastMonth') {
      dateStart = DateTimeService.formattedDateTime(subMonths(new Date(), 1))
    } else {
      if (filters.endDate) {
        dateEnd = filters.endDate
      }

      if (filters.startDate) {
        dateStart = filters.startDate
      }
    }

    return { dateStart, dateEnd }
  }

  const debouncedSendEventSearch = debounce(
    (term) => {
      sendEventSearch(term)
      // G360 Event for Search
      dataLayer.push({
        event: 'asEvent',
        analyticsEventLabel: 'Search',
        eventCategory: 'Search',
        eventAction: 'Search',
        eventLabel: ''
      })
    },
    500,
    {
      leading: false,
      trailing: true
    }
  )

  return (
    <div>
      <div>
        {(isLandScape || isTablet) && (
          <div style={{ margin: '10px -10px 0 -10px' }}>
            <LatestNews type="mobile" />
          </div>
        )}
      </div>

      {quickLinks.showLiveTvMobileInGrid && (
        <div
          style={{
            margin: '0px -10px 10px'
          }}
        >
          <MobileLiveTv
            hideLiveTvMobile={hideLiveTvMobile}
            isAsharqAppPopupVisible={quickLinks.isAsharqAppPopupVisible}
          />
        </div>
      )}

      <Container>
        {visibleFilters && (
          <>
            <ModalCloseIcon onClick={() => setFiltersVisibility(false)}>
              <img src={closeIcon} alt="close" loading="lazy" />
            </ModalCloseIcon>
            <Modal
              isOpen={visibleFilters}
              onRequestClose={() => setFiltersVisibility(false)}
            >
              <Filters
                categories={categories}
                filters={filters}
                setFilters={(res) => {
                  navigate(
                    `/${language}/search/?query=${searchTerm}&type=${res.type}&range=${res.selectedRange}`
                  )
                  setFilters(res)
                }}
                language={language}
                immediateUpdate={false}
                setFiltersVisibility={setFiltersVisibility}
                showCloseIcon
                showTimeWeightFilter={showTimeWeightFilter}
              />
            </Modal>
          </>
        )}

        <div>
          {!isLandScape && !isTablet && width >= breakpoints.m && (
            <>
              <Filters
                categories={categories}
                filters={filters}
                setFilters={(res) => {
                  navigate(
                    `/${language}/search/?query=${searchTerm}&type=${res.type}&range=${res.selectedRange}`
                  )
                  setFilters(res)
                }}
                language={language}
                showTimeWeightFilter={showTimeWeightFilter}
              />
              {searchTerm && searchTerm !== '' && (
                <FiltersContainer style={{ marginTop: '17px' }}>
                  <SocialControls
                    itemType={'search'}
                    theme="red"
                    themehover="midgrey"
                    visible={true}
                    vertical={true}
                    label={'مشاركة نتائج البحث'}
                    article={{
                      title: 'الشرق للأخبار | آخر الأخبار العربية والعالمية',
                      id: 'search-lg',
                      type: 'search'
                    }}
                    page={'articlepage'}
                    activeTheme="red-active"
                    showBookmark={false}
                    showLike={false}
                    showWhatsApp={true}
                    customShareUrl={window.location.href}
                  />
                </FiltersContainer>
              )}
            </>
          )}
        </div>

        <SearchContainer>
          <div>
            <SearchInputWrapper>
              <InputContainer>
                <Input
                  ref={searchInputRef}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => {
                    setTimeout(() => {
                      setIsFocused(false)
                      // sometimes searchTerm state is not correctly reflecting, so taking the current value from input ref
                      const searchTerm =
                        searchInputRef && searchInputRef.current
                          ? searchInputRef.current.value
                          : searchText
                      /*
                      onBlur is called even after user navigates to articles by clicking history article
                      making the user go back to search page even hence checking whether the user is in
                      search page to update the page with search query
                    */
                      if (
                        searchTerm &&
                        window.location.pathname.includes('/search') &&
                        window.location.search.includes('?query=')
                      ) {
                        debouncedSendEventSearch(searchTerm)
                        navigate(
                          `/${language}/search/?query=${searchTerm}&type=${articleType}&range=${
                            selectedRange || 'lastMonth'
                          }`
                        )
                      }
                    }, 500)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (searchText) {
                        debouncedSendEventSearch(searchText)
                        navigate(
                          `/${language}/search/?query=${searchText}&type=${articleType}&range=${
                            selectedRange || 'lastMonth'
                          }`
                        )
                      }
                      searchInputRef.current.blur()
                    }
                  }}
                />

                <img
                  className="search"
                  src={searchIcon}
                  alt="Search"
                  loading="lazy"
                />
                <img
                  onClick={() => setSearchText('')}
                  className="clear"
                  src={clearIcon}
                  loading="lazy"
                  alt="Clear Search"
                />
              </InputContainer>
              {testMobile() && filters.type !== 'all' ? (
                <BackButton
                  onClick={() => {
                    setFilters({
                      ...filters,
                      type: 'all'
                    })

                    navigate(
                      `/${language}/search/?query=${searchTerm}&type=all&range=${
                        selectedRange || 'lastMonth'
                      }`
                    )
                  }}
                >
                  <img src={BackIcon} alt="back to search" />
                </BackButton>
              ) : null}
            </SearchInputWrapper>

            <FlexWrapper>
              {(isLandScape || isTablet) && (
                <>
                  <FilterMobile onClick={() => setFiltersVisibility(true)}>
                    <img src={filterIcon} loading="lazy" alt="Filter Icon" />
                    التصفية
                  </FilterMobile>
                  {searchTerm && searchTerm !== '' && (
                    <SocialControls
                      itemType={'search'}
                      theme="red"
                      themehover="midgrey"
                      visible={true}
                      label={null}
                      article={{
                        title: 'الشرق للأخبار | آخر الأخبار العربية والعالمية',
                        id: 'search',
                        type: 'search'
                      }}
                      page={'articlepage'}
                      activeTheme="red-active"
                      showBookmark={false}
                      showLike={false}
                      showWhatsApp={true}
                      customShareUrl={window.location.href}
                    />
                  )}
                </>
              )}
            </FlexWrapper>

            <Results
              visible={isFocused && searchText.length}
              language={language}
              search={searchText}
              setSearch={setSearchText}
              debouncedSendEventSearch={debouncedSendEventSearch}
            />
          </div>

          <>
            {filters.type === 'all' ? (
              <>
                {cardResults && cardResults.fetching ? (
                  <>
                    <GridLoader>
                      <Spinner />
                    </GridLoader>
                  </>
                ) : (
                  <>
                    {cardResults.data &&
                    cardResults.data.liveTvSearch &&
                    cardResults.data.liveTvSearch.length ? (
                      <>
                        <ResultSubTitle>البث المباشر</ResultSubTitle>
                        <ResultContainer className="livetv">
                          {cardResults.data.liveTvSearch.map((ar, index) => (
                            <LiveTvCard
                              result={ar}
                              key={index}
                              searchTerm={searchTerm}
                              type="search"
                            />
                          ))}
                        </ResultContainer>
                        <LoadMoreBtnContainer>
                          <LoadMoreBtn
                            width="100%"
                            onClick={() => {
                              navigate(`/live/`, {
                                state: {
                                  searchTerm
                                }
                              })
                            }}
                          >
                            <span>{'أظهر المزيد'}</span>
                          </LoadMoreBtn>
                        </LoadMoreBtnContainer>
                      </>
                    ) : null}

                    {cardResults.data &&
                    cardResults.data.videos &&
                    cardResults.data.videos.length ? (
                      <>
                        <ResultSubTitle>فيديو</ResultSubTitle>
                        <ResultContainer>
                          {cardResults.data.videos.map((ar, index) => (
                            <Card
                              key={ar.id}
                              ar={ar}
                              index={index}
                              categories={categories}
                              type="search"
                            />
                          ))}
                        </ResultContainer>
                        {cardResults.data.videos.length === 3 ? (
                          <LoadMoreBtnContainer>
                            <LoadMoreBtn
                              width="100%"
                              onClick={() => handleLoadMore('video')}
                            >
                              <span>{'أظهر المزيد'}</span>
                            </LoadMoreBtn>
                          </LoadMoreBtnContainer>
                        ) : null}
                      </>
                    ) : null}

                    {cardResults.data &&
                    cardResults.data.articles &&
                    cardResults.data.articles.length ? (
                      <>
                        <ResultSubTitle>قصص وتقارير</ResultSubTitle>
                        <ResultContainer>
                          {cardResults.data.articles.map((ar, index) => (
                            <Card
                              key={ar.id}
                              ar={ar}
                              index={index}
                              categories={categories}
                              type="search"
                            />
                          ))}
                        </ResultContainer>
                        {cardResults.data.articles.length === 3 ? (
                          <LoadMoreBtnContainer>
                            <LoadMoreBtn
                              width="100%"
                              onClick={() => handleLoadMore('article')}
                            >
                              <span>{'أظهر المزيد'}</span>
                            </LoadMoreBtn>
                          </LoadMoreBtnContainer>
                        ) : null}
                      </>
                    ) : null}

                    {cardResults.data &&
                    cardResults.data.headlines &&
                    cardResults.data.headlines.length ? (
                      <>
                        <ResultSubTitle>آخر الأخبار</ResultSubTitle>
                        <ResultContainer>
                          {cardResults.data.headlines.map((ar, index) => (
                            <Card
                              key={ar.id}
                              ar={ar}
                              index={index}
                              categories={categories}
                              type="search"
                            />
                          ))}
                        </ResultContainer>
                        {cardResults.data.headlines.length === 3 ? (
                          <LoadMoreBtnContainer>
                            <LoadMoreBtn
                              width="100%"
                              onClick={() => handleLoadMore('headline')}
                            >
                              <span>{'أظهر المزيد'}</span>
                            </LoadMoreBtn>
                          </LoadMoreBtnContainer>
                        ) : null}
                      </>
                    ) : null}
                  </>
                )}
              </>
            ) : (
              <InfiniteScroll
                dataLength={
                  (cardResults.data &&
                    cardResults.data.articles &&
                    cardResults.data.articles.length) ||
                  0
                }
                next={() => {
                  if (cardResults.page > 0) {
                    fetchData({}, searchText)
                  }
                }}
                hasMore={cardResults.fetching ? true : cardResults.hasMore}
                style={{
                  width: '100%',
                  overflowY: 'hidden'
                }}
                endMessage={
                  <GridLoader
                    style={{
                      paddingTop: 0
                    }}
                  >
                    <p>لايوجد معروضات</p>
                  </GridLoader>
                }
                loader={
                  <GridLoader>
                    <Spinner />
                  </GridLoader>
                }
              >
                <>
                  {cardResults.data &&
                    cardResults.data.articles &&
                    cardResults.data.articles.map((ar, index) =>
                      getIsLiveTvSearch() ? (
                        <LiveTvCard
                          result={ar}
                          key={index}
                          searchTerm={searchTerm}
                        />
                      ) : (
                        <Card
                          key={ar.id}
                          ar={ar}
                          index={index}
                          categories={categories}
                          type="search"
                        />
                      )
                    )}
                </>
              </InfiniteScroll>
            )}
          </>
        </SearchContainer>

        {!isLandScape && !isTablet && width >= breakpoints.m && (
          <LatestNewsContainer>
            <LatestNews scrollable poll />
          </LatestNewsContainer>
        )}
      </Container>
    </div>
  )
}

const mapStateToProps = ({
  contextualSearch,
  categories,
  liveTvSearch,
  quickLinks
}) => {
  return {
    search: contextualSearch,
    categories: categories.data,
    liveTvSearch,
    quickLinks
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetSearch: () => {
      dispatch({ type: 'CONTEXTUAL_SEARCH_RESET' })
      dispatch({ type: 'LIVETV_SEARCH_RESET' })
    },

    fetchSearch: (payload) => {
      dispatch({ type: 'CONTEXTUAL_SEARCH_REQUESTED', payload })
    },

    fetchLiveTvSearch: (payload) => {
      dispatch({ type: 'LIVETV_SEARCH_REQUESTED', payload })
    },

    hideLiveTvMobile: () => {
      dispatch({ type: 'SHOW_HIDE_LIVE_TV_MOBILE', payload: false })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
