import React from 'react'
import { connect } from 'react-redux'

import Grid from '../../grid/grid'

const SearchGrid = ({
  language = 'ar',
  search,
  fetchSearch,
  resetSearch,
  ignoreLatestNews = false,
  searchCategory,
  searchTerm
}) => {
  const fetchSearchData = ({
    page = search.page,
    searchTerm,
    searchCategory,
    searchAuthor,
    gridSize
  }) => {
    fetchSearch({
      page,
      searchTerm,
      searchCategory,
      searchAuthor,
      language,
      gridSize,
      ignoreLatestNews,
      gridLength: search.data.length
    })
  }

  return (
    <Grid
      mode="search"
      searchCategory={searchCategory}
      searchTerm={searchTerm}
      language={language}
      fetchSearchData={fetchSearchData}
      resetSearch={resetSearch}
      gridData={search}
      key="search-grid"
      ignoreLatestNews={ignoreLatestNews}
    />
  )
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSearch: ({
      page,
      searchTerm,
      searchCategory,
      searchAuthor,
      language,
      gridSize,
      ignoreLatestNews,
      gridLength
    }) => {
      const payload = {
        lang: language,
        page,
        searchTerm,
        searchCategory,
        searchAuthor,
        gridSize,
        ignoreLatestNews,
        gridLength
      }

      dispatch({ type: 'SEARCH_REQUESTED', payload })
    },
    resetSearch: () => {
      dispatch({ type: 'SEARCH_RESET' })
    }
  }
}

const mapStateToProps = ({ search }) => {
  return {
    search
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchGrid)
